import React from "react";
import PageTitle from "../PageTitle";
import { motion } from "framer-motion";

// Primary ReactJs Function
function Contact(props) {
  // Enclosing tag in the return necesssary when adjacent elements used
  return (
    <div className="page-content contact contain">
      <motion.div
        className="actions"
        initial={{ opacity: 0, transform: "translate(0%,-100%)" }}
        animate={{ opacity: 1, transform: "translate(0%,0%)" }}
        transition={{
          opacity: { duration: 1.5 },
          transform: { duration: 0.35 },
        }}
      >
        <PageTitle name="Contact" />
        <div>
          Contact me for more information about my work and how it can intersect
          your needs. I love learning about new project ideas.
        </div>

        <div>
          <p>
            Phone: <a href="tel:+15026459250">(502) 645 9250</a>
          </p>
          <p>
            Email:{" "}
            <a href="mailto:tylerakinmwc@gmail.com">tylerakinmwc@gmail.com</a>
          </p>
        </div>
      </motion.div>
    </div>
  );
}

// Export app function to use it in index.js
export default Contact;
